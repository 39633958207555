.homePageCard {
    border-radius: 8px;
    border:1px solid lightgrey;
}
.homePageCard:hover {
    transform: translate3D(0,-1px,0) scale(1.1);
    border-radius: 8px;
    border:2px solid black;
}
.five-columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.column-item {
    flex-basis: 19%;

}

.sectionSeparator{
    padding-bottom: 25px;
}

.noMargin  p,  .noMargin >  ul, .noMargin > ol, .noMargin > li > ul{
    margin: 0 0 5px;
    padding: 0;
    /*overflow-wrap: anywhere;*/
    word-break: break-word;
}

 ol li, ul li{
    padding-left: 15px;
     margin-bottom: 5px;
}

.pillForTeamPage {
    background-color: white;
    border:1px solid lightgrey;
    color: black;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 1px 1px;
    border-radius: 32px;
    max-width: 300px;
    word-break: break-word;
    font-size: 15px
}

iframe#webpack-dev-server-client-overlay{display:none!important}
