.namePill {
    background-color: white;
    border:1px solid lightgrey;
    color: black;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 1px 1px;
    border-radius: 32px;
    max-width: 160px;
    word-break: break-word;
    font-size: 15px
}

.badgeWidth {
    max-width: 160px;
    white-space: break-spaces;
}

